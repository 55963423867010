import React from "react";
import homeimg from "../../assets/qixinhomebanner.webp";

const Hero = () => {
  return (
    <section className="flex flex-col md:flex-row items-center justify-between p-8 md:px-48 md: py-24 bg-white">
      {/* Text Section */}
      <div className="text-section max-w-lg space-y-12">
        <h1 className="text-4xl font-light leading-relaxed">
          <span className="text-[#F7936F]">Custom</span> solutions,
          <br />
          <span className="font-bold text-black">endless </span>
          <span
            className="font-bold bg-gradient-to-tr from-[#F7936F] to-[#FFEF5E] bg-clip-text text-transparent"
          >
            possibilities
          </span>
        </h1>
        <p className="text-gray-600">
          We help build, enhance, and transform your digital presence.
        </p>
        <a href="#footer" 
        className="bg-gradient-to-tr from-[#F7936F] to-[#FFEF5E] text-black font-semibold px-8 py-4 rounded shadow-md hover:shadow-lg inline-block"
        aria-label="Navigate to the footer section">
          Contact Us</a>
      </div>

      {/* Image Section */}
      <div className="image-section mt-8 md:mt-0">
        <img
          src={homeimg}
          alt="QX Tech coding illustrate"
          className="w-full max-w-md"
        />
      </div>
    </section>
  );
};

export default Hero;
