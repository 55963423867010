import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import uplus from "../../assets/partnersLogo/uplus.png"
import topAgent from "../../assets/partnersLogo/topAgent.png"
import hiHome from "../../assets/partnersLogo/hi-home_logo.webp"
import brightstone from "../../assets/partnersLogo/brightstone_legal_logo.webp"
import canberry from "../../assets/partnersLogo/canberry_logo.webp"
import liveComfy from "../../assets/partnersLogo/Live_Comfy_Landscape_Logo.png"
import laCasa from "../../assets/partnersLogo/La_Casa_Property_Group_logo.webp"
import taxAccounting from "../../assets/partnersLogo/Tax_Accounting_Services_logo.webp"
import bj from "../../assets/partnersLogo/bj-architectures-logo-transparent.png"
import starry from "../../assets/partnersLogo/Starry-Home-Renovation_logo.webp"

const links =[
    {
        id: 1,
        companyName: "Uplus Real Estate",
        logo: uplus,
        companyLink: "https://www.uplusrealty.com.au/",
    },
    {
        id: 2,
        companyName: "Top Agent",
        logo: topAgent,
        companyLink: "https://tarealty.com.au/",
    },
    {
        id: 3,
        companyName: "Hi Home Finance",
        logo: hiHome,
        companyLink: "https://www.hihomefinance.com.au/",
    },
    {
        id: 4,
        companyName: "Brightstone",
        logo: brightstone,
        companyLink: "https://www.brightstonelegal.com.au/en/",
    },
    {
        id: 5,
        companyName: "Canberry Properties",
        logo: canberry,
        companyLink: "https://www.canberryproperties.com.au/",
    },
    {
        id: 6,
        companyName: "LiveComfy Pty Ltd",
        logo: liveComfy,
        companyLink: "https://livecomfy.com.au/",
    },
    {
        id: 7,
        companyName: "La Casa Property Group",
        logo: laCasa,
        companyLink: "https://www.lacasapropertygroup.com.au/",
    },
    {
        id: 8,
        companyName: "鼎立会计师事务所",
        logo: taxAccounting,
        companyLink: "https://www.qixin.com.au/company/96",
    },
    {
        id: 9,
        companyName: "BJ Architects International",
        logo: bj,
        companyLink: "https://bjarchitects.com.au/",
    },
    {
        id: 10,
        companyName: "Starry Home Renovation",
        logo: starry,
        companyLink: "https://starryreno.com.au/",
    },
]

const LogoBanner = () => {
        {/* Slider settings */}
        var settings = {
            dots: false,
            infinite: true,
            slidesToShow: 7,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1000,
            pauseOnHover: true,
            rtl: true,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,  // Show 3 cards on tablets
                        slidesToScroll: 1,
                    },
                },
            ],
          };
    
    return (
        <div>
            {/* title */}
            <div>
                <h1 className="pl-10 md:pl-48 text-[#333333] text-2xl pt-6 relative inline-block">
                <span className="absolute top-0 left-10 md:left-48 w-[30%] h-1 bg-gradient-to-r from-[#F7936F] to-[#FFEF5E]"></span>
                Meet the Companies <br /> 
                    <span className="font-semibold">We are Working With</span></h1></div>
            
            
            {/* logos slider */}
            <div className="slider-container p-10">
                <Slider {...settings}>
                {links.map((data) =>(
                    <div key={data.id}>
                        <img src={data.logo} alt={data.companyName}
                        className="w-fit h-[70px]"/>
                    </div>
                ))}
                </Slider>
            </div>
        </div>
    )
}
export default LogoBanner;