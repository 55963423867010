import React from "react";
import testscreen from "../../assets/test.png"

import goldentank from "../../assets/cases/goldentank_screenshot.png"
import screenshot2 from "../../assets/cases/JLVariety_screenshot.png"
import vatee from "../../assets/cases/vatee_screenshot.png"

const Cases = () => {
    return (
        <div className="bg-white pb-10" id="cases">
        {/* title */}
        <div className="flex flex-col items-center text-[#333333] text-2xl pt-8">
            <div className="h-1 w-16 justify-between bg-gradient-to-r from-[#F7936F] to-[#FFEF5E]"></div>
            <h1 className="flex justify-center mt-6 mb-2">Our recent <br /></h1>
            <h2 className="flex justify-center font-semibold pb-10">Case studies</h2>
        </div>

        {/* cards */}
        <div className="pt-10 px-12 grid col-span-1 lg:col-span-2 gap-10">
            {/* card 1 */}
            <a href="https://goldentank.com.au/" aria-label="Visit the Golden Tank website for more information" title="Golden Tank - Learn financial trading online">
            <img className="col-start-1 w-full h-full hover:scale-105" src={goldentank} alt="golden tank website screenshot"/></a>
            <div className="lg:col-start-2 w-full bg-[#F5D791] text-[#333333]">
                <h3 className="flex justify-center font-semibold mt-6">Web Development for Golden Tank</h3>
                <p className="mt-3 mb-6 mx-10">
                    {/* mobile view */}
                    <span className="block md:hidden">We worked with Golden Tank to create a professional website that showcases their services. Explore the site at <a href="https://goldentank.com.au/" className="hover:text-blue-500">Golden Tank</a>.</span>
                    {/* laptop view - full content */}
                    <span className="hidden md:block">
                        We worked with Golden Tank, a financial services company in Canberra, to create a professional website that would showcase their financial trading education services and build trust with potential clients. The goal was to design a platform that reflected the company’s credibility and expertise. We developed a user-friendly site that highlighted course offerings and expert guidance, with a focus on professionalism and clear navigation. The result was a successful launch, boosting user trust, enhancing brand visibility, and strengthening client engagement. Explore the site at <a href="https://goldentank.com.au/" className="hover:text-blue-500">Golden Tank</a>.
                    </span></p>
            </div>

            {/* card 2 */}
            <a href="https://www.jlvarietystore.com.au/" aria-label="Explore a wide range of high-quality tableware and home products at JL Variety Store" title="JL Variety Store - Explore and shop tableware and home products">
            <img className="lg:row-start-2 w-full h-full hover:scale-105" src={screenshot2} alt="JL Variety website screenshot"/></a>
            <div className="lg:row-start-2 lg:col-start-2 w-full bg-[#C8D2C3] text-[#333333]">
                <h3 className="flex justify-center font-semibold mt-6">Web Development for JL Variety</h3>
                <p className="mt-3 mb-6 mx-10">
                    {/* mobile view */}
                    <span className="block md:hidden">We created a user-friendly e-commerce site for JL Variety, an online tableware startup, using Shopify. The result was a successful launch, increased orders, and positive feedback. Visit at <a href="https://www.jlvarietystore.com.au/" className="hover:text-blue-500">JL Variety</a>.</span>
                    {/* laptop view - full content */}
                    <span className="hidden md:block">
                        We worked with JL Variety, an online tableware startup, to create a user-friendly e-commerce website that would allow them to easily manage and sell their products. With no technical expertise in-house, they needed a simple yet scalable solution. We built the site on Shopify, offering a seamless shopping experience and integrated payment system. The result was a successful website launch, with positive customer feedback, increased engagement, and a noticeable boost in orders, giving JL Variety a solid foundation for growth in the online retail space. Visit the site at <a href="https://www.jlvarietystore.com.au/" className="hover:text-blue-500">JL Variety</a>.
                    </span></p>
            </div>

            {/* card 3 */}
            <a href="https://vateefx.com/" aria-label="Visit Vatee FX for CFD trading and financial services" title="Vatee FX - CFD trading and financial services in Sydney">
            <img className="lg:row-start-3 w-full h-full hover:scale-105" src={vatee} alt="Vatee website screenshot"/></a>
            <div className="lg:row-start-3 lg:col-start-2 w-full bg-[#EBAF64] text-[#333333]">
                <h3 className="flex justify-center font-semibold mt-6">Web development for Vatee</h3>
                <p className="mt-3 mb-6 px-10">
                    {/* mobile view */}
                    <span className="block md:hidden">We partnered with Vatee Pty Ltd, adding MT4 and CRM integrations to their website. The result was a professional site that boosted credibility and client retention. Visit <a href="https://vateefx.com/" className="hover:text-blue-500">Vatee</a>.</span>
                    {/* laptop view - full content */}
                    <span className="hidden md:block">We partnered with Vatee Pty Ltd, a CFD broker in Sydney, to redesign their website and improve both its functionality and professionalism. Their previous site lacked the features necessary to build client trust, and they needed seamless integration with their CRM and MT4 platform. In just one month, we delivered a user-friendly website with API integrations for MT4 Manager, MT4 Admin, and the CRM system. The result was a polished, functional site that enhanced Vatee’s credibility, streamlined operations, and improved client retention. Visit the site at <a href="https://vateefx.com/"className="hover:text-blue-500">Vatee</a>. 
                    </span></p>
            </div>
        </div>
        </div>
    )
}
export default Cases