import React from 'react'
import logo from '../../assets/logo.png'

import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import xhs from '../../assets/XiaohongshuLOGO.png';


const Footer = () => {
   return (
       <div id='footer'>
           {/* logo & links */}
           <div  className='grid grid-cols-1 md:grid-cols-3 justify-between items-center mb-3 gap-3'>
               {/* logo */}
               <div className="w-auto p-3 ml-12">
                   <img className="object-cover h-16 w-16 p-3" src={logo} alt="qixin logo"/>
                   {/* 企业介绍改这里 */}
                   <p className='self-center text-base text-gray-500 pl-3'>QX Tech is an innovative IT company dedicated to helping businesses grow through customised digital solutions.</p>
               </div>
               
               {/* links 在这里修改链接 */}
               <div className='flex flex-col relative top-7 text-gray-500 text-base p-6 mx-6'>
                   <p className="font-semibold mx-6 mb-3 md:mx-12">Links</p>
                   <p className="hover:underline mx-6 mb-1 md:mx-12"><a href='#about'>About Us</a></p>
                   <p className="hover:underline mx-6 mb-1 md:mx-12"><a href='#services'>Services</a></p>
                   <p className="hover:underline mx-6 mb-1 md:mx-12"><a href='#cases'>Case Studies</a></p>
                   <p className="hover:underline mx-6 mb-1 md:mx-12"><a href='#workwithus'>Collaboration</a></p>
                   <p className="hover:underline mx-6 mb-1 md:mx-12"><a href='#'>Careers</a></p>
               </div>

               <div className='flex flex-col items-left text-gray-500 text-base p-6 ml-12 mr-6'>
                   <p className="font-semibold mb-3 md:pt-7">Contact Us</p>
                   <p className="mb-1">547 / Level 5, 15 Moore Street </p>
                   <p className="mb-1">Canberra ACT 2601</p>
                   <p className="hover:underline mb-1"><a href="mailto:info@qixin.com.au">info@qixin.com.au</a></p>
                   <p className="hover:underline mb-1"><a href="tel:+61 484411111">+61 484411111</a></p>
               </div>
           </div>
           
           {/* 社交媒体share button */}
           <div className='flex flex-wrap justify-end p-3'>
               <a href='https://www.linkedin.com/company/qixin-co-pty-ltd/' aria-label="Visit Qixin Co. Pty Ltd LinkedIn profile for company updates and business information" title="Qixin Co. Pty Ltd - Connect with us on LinkedIn for company updates">
                <FaLinkedin className='h-6 w-6 mx-2 hover:scale-105'/></a>
               <a href='https://www.instagram.com/qixincoptyltd?igsh=MXJ0bWtqbGliYm85ZA==' aria-label="Visit Qixin Co. Pty Ltd Instagram profile for photos, updates, and business insights" title="Follow Qixin Co. Pty Ltd on Instagram for the latest updates and company news">
                <FaInstagram className='h-6 w-6 mx-2 hover:scale-105' /></a>
               <a href='https://www.xiaohongshu.com/user/profile/61227299000000000101e708?xhsshare=CopyLink&appuid=6559625e000000000802f60a&apptime=1733874462&share_id=c7f772b6f3274e87833b39da9a2528a0' aria-label="Visit Qixin Co. Pty Ltd profile on Xiaohongshu (Little Red Book 小红书) for product recommendations and lifestyle content" title="Explore Qixin Co. Pty Ltd on Xiaohongshu for lifestyle tips, product reviews, and updates">
                <img src={xhs} alt='xiaohongshu share button' 
               className='object-cover h-6 w-6 mx-2 hover:scale-105'/></a>
           </div>

           {/* copyright section */}
           <hr className="border-gray-200 sm:mx-auto dark:border-gray-700" />
           <div>
               <p className='text-gray-500 sm:text-center m-6'>© 2024 Copyright by QX Tech. All rights reserved.</p>
           </div>
       </div>
   )
}

export default Footer