import React, { useState, useEffect } from 'react';
import logo from '../../assets/logo.png';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const Header = () => {
    const [nav, setNav] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [isScrollingDown, setIsScrollingDown] = useState(false);
    const [showNav, setShowNav] = useState(true);

    const handleNav = () => {
        setNav(!nav);
    };

    const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
            // Scrolling Down
            setIsScrollingDown(true);
        } else {
            // Scrolling Up
            setIsScrollingDown(false);
        }
        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        if (isScrollingDown) {
            // Wait 2 seconds after scrolling down, then hide the navbar
            const hideNavTimeout = setTimeout(() => {
                setShowNav(false);
            }, 2000);

            return () => clearTimeout(hideNavTimeout); // Clean up timeout
        } else {
            setShowNav(true); // Show navbar immediately on scroll up
        }

        // Clean up the event listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isScrollingDown, lastScrollY]);

    return (
        <header className={`flex justify-between items-center h-18 w-full mx-auto px-8 text-[#333333] sticky top-0 z-10 bg-slate-50 transition-transform ${showNav ? 'transform-none' : '-translate-y-full'}`}>
            <div className='flex justify-between items-center'>
                <img className='w-10 h-8' src={logo} alt='QX Tech logo' />
                <a href='it.qixin.com.au'>
                    <h1 className='w-full text-xl font-semibold pl-2 pt-4 pb-4 sm:pt-0 sm:pb-0 hover:bg-gradient-to-tr hover:from-[#F7936F] hover:to-[#FFEF5E] hover:bg-clip-text hover:text-transparent'>QX Tech</h1>
                </a>
            </div>

            <ul className="hidden sm:flex sm:flex-nowrap">
                <li className="p-4 px-4 lg:px-8 hover:text-[#F7936F]">
                    <a href="#services" aria-label="View services offered by our company">Services</a>
                </li>
                <li className="p-4 px-4 lg:px-8 hover:text-[#F7936F]">
                    <a href="#cases" aria-label="Explore case studies and projects">Cases</a>
                </li>
                <li className="p-4 px-4 lg:px-8 hover:text-[#F7936F]">
                    <a href="#workwithus" aria-label="Learn about collaboration opportunities">Collaboration</a>
                </li>
                <li className="p-4 px-4 lg:px-8 hover:text-[#F7936F]">
                    <a href="#about" aria-label="About our company and team">About Us</a>
                </li>
            </ul>


            {/* mobile */}
            <div onClick={handleNav} className='block sm:hidden'>
                {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
            </div>

            <div className={nav ? 'fixed left-0 top-0 z-10 w-[40%] bg-slate-50 ease-in-out duration-500' : 'fixed left-[-100%]'}>
                <div className='flex p-4'>
                    <img className='w-10 h-8' src={logo} alt='QX Tech logo' />
                    <h1 className='w-full text-xl font-semibold pl-2 hover:bg-gradient-to-tr hover:from-[#F7936F] hover:to-[#FFEF5E] hover:bg-clip-text hover:text-transparent'>QX Tech</h1>
                </div>

                <ul className="pt-6">
                    <li className="p-2 px-6 border-b hover:text-[#F7936F]">
                        <a href="#services" aria-label="View services offered by our company">Services</a>
                    </li>
                    <li className="p-2 px-6 border-b hover:text-[#F7936F]">
                        <a href="#cases" aria-label="Explore case studies and projects">Cases</a>
                    </li>
                    <li className="p-2 px-6 border-b hover:text-[#F7936F]">
                        <a href="#workwithus" aria-label="Learn about collaboration opportunities">Collaboration</a>
                    </li>
                    <li className="p-2 px-6 hover:text-[#F7936F]">
                        <a href="#about" aria-label="About our company and team">About Us</a>
                    </li>
                </ul>

            </div>
        </header>
    );
};

export default Header;
