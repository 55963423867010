import React from "react";

const WorkWithUs = () =>{
   return(
       <div className="bg-white" id="workwithus">
           <div className="border-t-[1px] border-slate-50 w-full mx-auto"></div>
           {/* Page Title */}
           <div className="w-full flex flex-col items-center p-10">
               <div className="h-1 w-16 bg-gradient-to-r from-[#F7936F] to-[#FFEF5E]"></div>
               <h1 className="mt-6 font-semibold text-[#333333] text-2xl">Our Collaborative Approach to Driving Your Success</h1>
           </div>

            {/* Timeline */}
            <div class="mx-auto grid grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:grid-cols-4 p-12">
                {/* Step 1: Gathering Requirements & Planning */}
                <div>
                    <time datetime="Step 1" class="flex items-center text-sm font-semibold leading-6 text-orange-600 dark:text-orange-400">
                        <svg viewBox="0 0 4 4" class="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                        <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
                        </svg> Step 1
                        <div class="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 dark:bg-gray-500 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0" aria-hidden="true"></div>
                    </time>
                    <p class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">Gathering Requirements & Planning</p>
                    <p class="mt-1 text-sm text-pretty leading-7 text-gray-500">
                        We start by meeting with your team to understand the key goals, features, and functionality needed for the app. This helps us create a clear plan, outlining the project’s scope, priorities, and a timeline for delivery.
                    </p>
                </div>

                {/* Step 2: Development Process */}
                <div>
                    <time datetime="Step 2" class="flex items-center text-sm font-semibold leading-6 text-orange-600 dark:text-orange-400">
                        <svg viewBox="0 0 4 4" class="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                        <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
                        </svg> Step 2
                    <div class="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 dark:bg-gray-500 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0" aria-hidden="true"></div>
                    </time>
                    <p class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">Development Process</p>
                    <p class="mt-1 text-sm text-pretty leading-7 text-gray-500">
                        Throughout development, we work in stages, delivering key features and functionality step by step. At the end of each stage, we’ll share a working version for your review and feedback.
                    </p>
                </div>

                {/* Step 3: Review & Final Delivery */}
                <div>
                    <time datetime="Step 3" class="flex items-center text-sm font-semibold leading-6 text-orange-600 dark:text-orange-400">
                        <svg viewBox="0 0 4 4" class="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                            <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
                        </svg> Step 3
                        <div class="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 dark:bg-gray-500 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0" aria-hidden="true"></div>
                    </time>
                    <p class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">Review & Final Delivery</p>
                    <p class="mt-1 text-sm text-pretty leading-7 text-gray-500">
                        After completing the development, we conduct a final review with your team to ensure the app meets all requirements. Once everything is approved, we prepare the app for submission to app stores.
                    </p>
                    </div>

                    {/* Step 4: Project Closure */}
                    <div>
                        <time datetime="Step 4" class="flex items-center text-sm font-semibold leading-6 text-orange-600 dark:text-orange-400">
                            <svg viewBox="0 0 4 4" class="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                                <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
                            </svg> Step 4
                            <div class="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 dark:bg-gray-500 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0" aria-hidden="true"></div>
                        </time>
                        <p class="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">Project Closure</p>
                        <p class="mt-1 text-sm text-pretty leading-7 text-gray-500">
                            Once the app is live, we provide post-launch support and schedule a project review. We’ll discuss feedback, ensure satisfaction, and provide any necessary updates.
                        </p>
                    </div>
                </div>

        </div>
    )
}

export default WorkWithUs;
